import logo from "./images/1.jpg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to Monad Punks!</h1>
        <div class="centered-content">
          <h5>
            We're excited to announce that the{" "}
            <a href="https://VenomPunks.com" target="_blank">
              VenomPunks.com
            </a>{" "}
            team has acquired Monad Punks. Stay tuned for updates on our
            enhanced whitepaper, roadmap, and more.
          </h5>
          <h5>{`Just to clarify, Monad Punks will remain on the Monad blockchain.
        However, there's a new team in charge: the Venom Punks team.`}</h5>
        </div>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <a
            className="App-link"
            href="https://twitter.com/venom_punks"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          &nbsp; | &nbsp;
          <a
            className="App-link"
            href="https://discord.gg/jGnQbU5E"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
